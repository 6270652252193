import React , {useState , useContext} from 'react';
import firebase from './components/firebase'
import { AuthContext } from './context/auth';



const Signup =()=>{
    const [ data , setData] = useState({
        email : "",
        password:"",
        error: null,
    })
const {setUser} = useContext(AuthContext)

const handleChange = e=>{
    setData ({...data,[e.target.name]:e.target.value})
}


const handleSubmit = async e =>{
    e.preventDefault()

    try{
const result = await firebase
.auth()
.createUserWithEmailAndPassword(data.email , data.password)
setUser(result)

    }catch(err){
        setData({...data ,error: err.message})

    }
}

    return (
        <>
<h4>
    Register
</h4>
<form onSubmit ={handleSubmit}>
<div>
<label htmlFor  = 'email'>Email</label>
<br/>
<input type = 'text' name = 'email' value = {data.email} onChange = {handleChange}/>
<br/>
<br/>
</div>



<div>
    <label htmlFor = 'password' name = 'password'>Password</label>
    <br/>
    <input
    type = 'password'
    name = 'password'
    value = {data.password}
    onChange = {handleChange}
    />
    </div>
{data.error?<p>{data.error}</p>:null}
<input type = 'submit' value = 'Register'/>


</form>

        </>
    )

}
export default Signup